/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { HIRL } from '../../types/infrastructure/hirl';

export type ParamsType = {
  database: HIRL;
};
export type ResponseType = void;
export const Endpoint = 'infrastructure/terminate-database';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"database":{"anyOf":[{"type":"object","properties":{"kind":{"type":"string","enum":["app/server"]},"uuid":{"type":"string"}},"required":["kind","uuid"]},{"type":"object","properties":{"kind":{"type":"string","enum":["app/database"]},"uuid":{"type":"string"}},"required":["kind","uuid"]},{"type":"object","properties":{"kind":{"type":"string","enum":["app/postgres"]},"uuid":{"type":"string"}},"required":["kind","uuid"]},{"type":"object","properties":{"kind":{"type":"string","enum":["app/redis"]},"uuid":{"type":"string"}},"required":["kind","uuid"]},{"type":"object","properties":{"kind":{"type":"string","enum":["aws/s3-bucket"]},"uuid":{"type":"string"}},"required":["kind","uuid"]}]}},"required":["database"],"$schema":"http://json-schema.org/draft-07/schema#"}