/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
/**
 * PUT database allows for the creation in the system of a database that already exists.
 */

import { DatabaseHostingType, DatabaseKindEnum } from '../../types/infrastructure/database';

export type ParamsType = {
  name: string;
  hosting: DatabaseHostingType;
};
export type ResponseType = { uuid: string; };
export const Endpoint = 'infrastructure/put-database';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"name":{"type":"string"},"hosting":{"$ref":"#/definitions/DatabaseHostingType_DigitalOcean"}},"required":["hosting","name"],"definitions":{"DatabaseHostingType_DigitalOcean":{"type":"object","properties":{"location":{"type":"string","enum":["digitalOcean"]},"uuid":{"type":"string"}},"required":["location","uuid"]}},"$schema":"http://json-schema.org/draft-07/schema#"}