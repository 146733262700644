/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
/**
 * PUT database allows for the creation in the system of a database that already exists.
 */

import { DatabaseStatusEnum } from "../../types/infrastructure/database";

export type ParamsType = null;
export type ResponseType = {
  databases: Array<{
    name: string;
    uuid: string;
    status: DatabaseStatusEnum;
  }>;
};
export const Endpoint = 'infrastructure/list-databases';
export const Method = 'GET';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
};export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}