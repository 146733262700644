/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export enum OperationStatusEnum {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
    SUCCEEDED = 'SUCCEEDED',
}

export interface OperationStepType {
    label: string;
    uuid: string;
    status: OperationStatusEnum;
    started: Date | null;
    finished: Date | null;
}

export interface OperationType {
    label: string;
    uuid: string;
    created: Date;
    modified: Date;
    status: OperationStatusEnum;
    steps: OperationStepType[];
    failureLogs?: string | null;
}