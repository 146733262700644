/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable require-await */
import { MoopsyClient, MoopsyClientAuthExtension } from '@moopsyjs/react';
import axios from "axios";

export const seamlessClient = new MoopsyClient({ socketUrl: window.location.host === 'localhost:3000' ? 'http://localhost:3001' : '/', axios });
export const seamlessAuth = new MoopsyClientAuthExtension<{PublicAuthType: {userId: string}, AuthRequestType: {token: string}}>(seamlessClient, {
  autoLoginFunction: async () => {
    const token = window.sessionStorage.token;

    if (token != null) {
      return { token };
    }

    return null;
  }
});
