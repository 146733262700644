/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { AppServerAppVariant, AppServerCustomConfig, GeographicLocation, CloudProvider } from '../../types/infrastructure/app-server';
import { HIRL } from '../../types/infrastructure/hirl';

export type ParamsType = {
  name: string;
  hostLocation: CloudProvider;
  location: GeographicLocation;
  variant: AppServerAppVariant;
  custom?: AppServerCustomConfig | null;
  mongo?: HIRL | null;
};
export type ResponseType = { operationUUID: string; appServerUUID: string; };
export const Endpoint = 'infrastructure/provision-app-server';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"name":{"type":"string"},"hostLocation":{"$ref":"#/definitions/CloudProvider"},"location":{"$ref":"#/definitions/GeographicLocation"},"variant":{"$ref":"#/definitions/AppServerAppVariant"},"custom":{"anyOf":[{"$ref":"#/definitions/AppServerCustomConfig"},{"type":"null"}]},"mongo":{"anyOf":[{"type":"object","properties":{"kind":{"type":"string","enum":["app/server"]},"uuid":{"type":"string"}},"required":["kind","uuid"]},{"type":"object","properties":{"kind":{"type":"string","enum":["app/database"]},"uuid":{"type":"string"}},"required":["kind","uuid"]},{"type":"object","properties":{"kind":{"type":"string","enum":["app/postgres"]},"uuid":{"type":"string"}},"required":["kind","uuid"]},{"type":"object","properties":{"kind":{"type":"string","enum":["app/redis"]},"uuid":{"type":"string"}},"required":["kind","uuid"]},{"type":"object","properties":{"kind":{"type":"string","enum":["aws/s3-bucket"]},"uuid":{"type":"string"}},"required":["kind","uuid"]},{"type":"null"}]}},"required":["hostLocation","location","name","variant"],"definitions":{"CloudProvider":{"enum":["awsEC2","digitalOceanAppPlatform","digitalOceanDroplet"],"type":"string"},"GeographicLocation":{"enum":["southeastasia","usnortheast"],"type":"string"},"AppServerAppVariant":{"enum":["custom","meetProxy","omni","smtp","stardate","themes"],"type":"string"},"AppServerCustomConfig":{"type":"object","properties":{"github":{"type":"string"}},"required":["github"]}},"$schema":"http://json-schema.org/draft-07/schema#"}