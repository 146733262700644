import React from 'react';
import moment from 'moment';
import { Table } from '@hiyllo/ux/table';

import * as GetBuildsListBP from '../../../blueprints/build-repo/get-builds-list';

import { PageContainer } from '../../ux/page-container';
import { Typography } from '@hiyllo/ux/typography';
import { seamlessClient } from '../../../seamless';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';

export const BuildsList = (): JSX.Element => {
  const getBuildsListQuery = seamlessClient.useQuery<GetBuildsListBP.Plug>(GetBuildsListBP, null);

  return (
    <PageContainer>
      <Typography.Header>Omni Builds</Typography.Header>
      {getBuildsListQuery.isLoading
        ? <FontAwesomeIcon icon={faSpinnerThird} spin/>
        : getBuildsListQuery.isError
          ? <div>Error</div>
          : <div>
            <Table
              columns={['commitHash', 'uuid', 'awsS3Key', 'date', 'name']}
              data={getBuildsListQuery.data.builds}
              rowMapper={item => [item.commit ?? '?', item.uuid, <a key={item.src} href={item.src}>{item.awsS3Key}</a>, moment(item.date).format('h:mm a, dddd MMM Do'), item.name ?? '?']}
            />
          </div>}

    </PageContainer>
  );
};
