/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { HIRL } from "./hirl";

export enum DatabaseKindEnum {
    mongo = 'mongo',
    redis = 'redis',
    postgres = 'pg'
}

export enum DatabaseHostingLocationEnum {
    digitalOcean = 'digitalOcean',
    mongodbAtlas = 'mongodbAtlas',
    awsEC2 = 'awsEC2',
}

export interface DatabaseHostingType_DigitalOcean {
    location: DatabaseHostingLocationEnum.digitalOcean;
    uuid: string;
}

export type DatabaseHostingType = DatabaseHostingType_DigitalOcean;

export enum DatabaseSpecKindEnum {
    standard = 'standard',
    serverless = 'serverless',
}

export type DatabaseSpecType = {
    kind: DatabaseSpecKindEnum.standard;
    memoryGB: number;
    vCPU: number;
    /** Disk in MB */
    disk: number;
}

export enum DatabaseStatusEnum {
    initializing = 'initializing',
    online = 'online',
    maintenance = 'maintenance',
}

export interface DatabaseType {
    uuid: string;
    kind: DatabaseKindEnum;
    name: string;
    hosting: DatabaseHostingType;
    spec: DatabaseSpecType;
    created: Date;
    modified: Date;
    synced: Date;
    status: DatabaseStatusEnum;
}