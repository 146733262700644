import React from "react";

import * as ListAllResourcesBP from "../../../../blueprints/infrastructure/list-all-resources";
import { seamlessClient } from "../../../../seamless";
import { Select } from "@hiyllo/ux/select";
import { HIRL, parseHIRL, stringifyHIRL } from "../../../../types/infrastructure/hirl";


export const ResourcePicker = React.memo(function ResourcePicker (props: {
    filter: HIRL["kind"]
    value: HIRL | null;
    onChange: (value: HIRL) => void;
}): JSX.Element {
    const resourcesQuery = seamlessClient.useQuery<ListAllResourcesBP.Plug>(ListAllResourcesBP, null);

    return (
        <Select
            options={[
                {value:null, label:"Select Resource..."},
                ...(resourcesQuery.data?.resources.filter(r => r.hirl.kind === props.filter).map(resource => ({
                    label: resource.name,
                    value: stringifyHIRL(resource.hirl)
                })) ?? [])
            ]}
            value={props.value == null ? null : stringifyHIRL(props.value)}
            onChangeValue={value => props.onChange(parseHIRL(value as string))}
        />
    );
});