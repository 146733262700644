import React from 'react';
import { Button } from '@hiyllo/ux/button';

import * as GetDatabaseBP from '../../../../blueprints/infrastructure/get-database';
import * as TerminateDatabaseBP from '../../../../blueprints/infrastructure/terminate-database';
import { PageContainer } from '../../../ux/page-container';
import { FlexRow } from '../../../ux/flex-row';
import { useNavigate, useParams } from 'react-router-dom';
import { seamlessClient } from '../../../../seamless';
import {LoadingSpinner } from '@hiyllo/ux/loading-spinner';
import { Typography } from '@hiyllo/ux/typography';
import { DangerButton } from '../../../ux/danger-button';
import { DatabaseHostingLocationEnum } from '../../../../types/infrastructure/database';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-light-svg-icons';



export const DatabaseView = React.memo(function DatabaseView (): JSX.Element {
  const { uuid } = useParams<{uuid:string}>();

  if(uuid == null) {
    throw new Error('uuid is required');
  }

  const databaseQuery = seamlessClient.useQuery<GetDatabaseBP.Plug>(GetDatabaseBP, {uuid});
  const terminateMutation = seamlessClient.useMutation<TerminateDatabaseBP.Plug>(TerminateDatabaseBP);
  const navigate = useNavigate();

  const terminate = React.useCallback(() => {
    void terminateMutation.call({database: {
      kind: 'app/postgres',
      uuid
    }}).then(() => {
      navigate('/databases');
    });
  }, [navigate, terminateMutation, uuid]);

  if(databaseQuery.isLoading) {
    return (
      <PageContainer>
        <LoadingSpinner/>
      </PageContainer>
    );
  }

  if(databaseQuery.isError) {
    return (
      <PageContainer>
        {databaseQuery.error.message}
      </PageContainer>
    );
  }  

  const {database} = databaseQuery.data;

  return (
    <PageContainer>
      <Typography.Header>
        {database.name}
      </Typography.Header>
      <FlexRow>
        <div>
          <b>Memory: </b> {database.spec.memoryGB}GB
        </div>
        <div>
          <b>vCPU: </b> {database.spec.vCPU}
        </div>
        <div>
          <b>Disk: </b> {database.spec.disk}MB
        </div>
        <div>
          <b>Hosted ID: </b> {database.hosting.uuid}
        </div>
        {database.hosting.location === DatabaseHostingLocationEnum.digitalOcean ?
          <a href={`https://cloud.digitalocean.com/databases/${database.hosting.uuid}`} target="_blank" rel="noreferrer">View on DigitalOcean <FontAwesomeIcon icon={faExternalLinkAlt}/></a>
        : null}
        
      </FlexRow>
      
      <DangerButton
        label="Terminate Database"
        onClick={terminate}
      />
    </PageContainer>
  );
});
