/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export enum InfrastructureTypeEnum {
  digitalOceanApp = 'digitalocean_app',
  redisDatabase = 'redis_database',
  mongoDatabase = 'mongo_database',
  s3 = 's3',
  awsSESIdentity = 'aws_ses_identity',
}

export enum InfrastructureStatusEnum {
  initializing = 'initializing',
  running = 'running',
  updating = 'updating',
  terminated = 'terminated',
}

export enum DigitalOceanInstanceSizeSlugs {
  /**
   * 512mb, 1vCPU
   */
  "basic-xxs" = "basic-xxs",
  /**
   * 1gb, 1vCPU
   */
  "basic-xs" = "basic-xs",
  /**
   * 2gb 1vCPU
   */
  "basic-s" = "basic-s",
  /**
   * 4gb, 2vCPU
   */
  "basic-m" = "basic-m",
  "professional-xs" = "professional-xs",
  "professional-s" = "professional-s",
  "professional-m" = "professional-m",
  "professional-1l" = "professional-1l",
  "professional-l" = "professional-l",
  "professional-xl" = "professional-xl",
}

export const DigitalOceanInstanceSizeSlugToSpec: Record<
  DigitalOceanInstanceSizeSlugs,
  {
    memoryGB: number;
    vCPU: number;
    dedicatedCPU: boolean;
  }
> = {
  "basic-xxs": {
    memoryGB: 0.5,
    vCPU: 1,
    dedicatedCPU: false,
  },
  "basic-xs": {
    memoryGB: 1,
    vCPU: 1,
    dedicatedCPU: false,
  },
  "basic-s": {
    memoryGB: 2,
    vCPU: 1,
    dedicatedCPU: false,
  },
  "basic-m": {
    memoryGB: 4,
    vCPU: 2,
    dedicatedCPU: false,
  },
  "professional-xs": {
    memoryGB: 1,
    vCPU: 1,
    dedicatedCPU: false,
  },
  "professional-s": {
    memoryGB: 2,
    vCPU: 1,
    dedicatedCPU: false,
  },
  "professional-m": {
    memoryGB: 4,
    vCPU: 2,
    dedicatedCPU: false,
  },
  "professional-1l": {
    memoryGB: 4,
    vCPU: 1,
    dedicatedCPU: true,
  },
  "professional-l": {
    memoryGB: 8,
    vCPU: 2,
    dedicatedCPU: true,
  },
  "professional-xl": {
    memoryGB: 16,
    vCPU: 4,
    dedicatedCPU: true,
  }
}

export enum DigitalOceanDatabaseSizeSlug {
  db1VCPU1GB = "db-s-1vcpu-1gb",
  db1VCPU2GB = "db-s-1vcpu-2gb",
  db2VCPU4GB = "db-s-2vcpu-4gb",
  db4VCPU8GB = "db-s-4vcpu-8gb",
  db6VCPU16GB = "db-s-6vcpu-16gb",
  db8VCPU32GB = "db-s-8vcpu-32gb",
  db16VCPU64GB = "db-s-16vcpu-64gb",
}

export const DigitalOceanDatabaseSlugToBaseSizeMB = {
  [DigitalOceanDatabaseSizeSlug.db1VCPU1GB]: 15*1000,
  [DigitalOceanDatabaseSizeSlug.db1VCPU2GB]: 34*100,
  [DigitalOceanDatabaseSizeSlug.db2VCPU4GB]: 56*1000,
  [DigitalOceanDatabaseSizeSlug.db4VCPU8GB]: 120*1000,
  [DigitalOceanDatabaseSizeSlug.db6VCPU16GB]: 248*1000,
  [DigitalOceanDatabaseSizeSlug.db8VCPU32GB]: 504*1000,
  [DigitalOceanDatabaseSizeSlug.db16VCPU64GB]: 1016*1000,
}

export interface DigitalOceanDomainType {
  type: "UNSPECIFIED" | "DEFAULT"  | "PRIMARY" | "ALIAS";
  wildcard: boolean;
  domain: string;
}

export interface DigitalOceanAppInfrastructureDetails {
  uuid: string;
  region: "nyc";
  instance_size_slug: DigitalOceanInstanceSizeSlugs;
  instance_count: number;
  ingressDomain: string;
  domains: DigitalOceanDomainType[];
  userEnvironmentVariables: {
    [k: string]: {
      value: string | null;
      encrypted: boolean;
    }
  }
}

export interface DigitalOceanMongoDatabaseInfrastructureDetails {
  uuid: string;
  region: "nyc";
  size: string;
  num_nodes: number;
}

export interface DigitalOceanRedisDatabaseInfrastructureDetails {
  uuid: string;
  region: "nyc";
  size: DigitalOceanDatabaseSizeSlug;
  num_nodes: 1 | 3;
}

export interface S3InfrastructureDetails {
  bucketName: string;
  region: "us-east-1";
}

export interface AWSSESIdentityInfrastructureDetails {
  region: "us-east-1";
  emailDomain: string;
  records: Array<{host:string, pointsTo:string}>;
  verified: boolean;
}

interface InfrastructureEntryBase {
  infraUuid: string;
  status: InfrastructureStatusEnum;
}

export interface DigitalOceanAppInfrastructureEntry extends InfrastructureEntryBase {
  type: InfrastructureTypeEnum.digitalOceanApp;
  details: DigitalOceanAppInfrastructureDetails;
}

export interface DigitalOceanMongoDatabaseInfrastructureEntry extends InfrastructureEntryBase {
  type: InfrastructureTypeEnum.mongoDatabase;
  details: DigitalOceanMongoDatabaseInfrastructureDetails;
}

export interface DigitalOceanRedisDatabaseInfrastructureEntry extends InfrastructureEntryBase {
  type: InfrastructureTypeEnum.redisDatabase;
  details: DigitalOceanRedisDatabaseInfrastructureDetails;
}

export interface S3InfrastructureEntry extends InfrastructureEntryBase {
  type: InfrastructureTypeEnum.s3;
  details: S3InfrastructureDetails;
}

export interface AWSSESIdentityInfrastructureEntry extends InfrastructureEntryBase {
  type: InfrastructureTypeEnum.awsSESIdentity;
  details: AWSSESIdentityInfrastructureDetails;
}

export type InfrastructureEntry = DigitalOceanAppInfrastructureEntry | DigitalOceanMongoDatabaseInfrastructureEntry | DigitalOceanRedisDatabaseInfrastructureEntry | S3InfrastructureEntry;

export interface InfrastructureStack {
  [InfrastructureTypeEnum.digitalOceanApp]: DigitalOceanAppInfrastructureEntry | null;
  [InfrastructureTypeEnum.mongoDatabase]: DigitalOceanMongoDatabaseInfrastructureEntry | null;
  [InfrastructureTypeEnum.redisDatabase]: DigitalOceanRedisDatabaseInfrastructureEntry | null;
  [InfrastructureTypeEnum.s3]: S3InfrastructureEntry | null;
  [InfrastructureTypeEnum.awsSESIdentity]: AWSSESIdentityInfrastructureEntry | null;
}

export interface InfrastructurePlan {
  maxUsers: number;
  instanceSize: DigitalOceanInstanceSizeSlugs;
  instanceCount: number;
  mongoSize: DigitalOceanDatabaseSizeSlug;
  mongoNumNodes: number;
  redisSize: DigitalOceanDatabaseSizeSlug;
}

export enum OperatingSystem {
  ubuntu = 'ubuntu',
  fedora = 'fedora',
  debian = 'debian',
}