/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { AppServerStatusEnum } from "../../types/infrastructure/app-server";

export type ParamsType = null;
export type ResponseType = {
  appServers: Array<{
    name: string;
    uuid: string;
    status: AppServerStatusEnum;
  }>;
};
export const Endpoint = 'infrastructure/list-app-servers';
export const Method = 'GET';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
};export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}