/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { OperatingSystem } from "../omni/infrastructure";
import { CloudProvider, GeographicLocation } from "./cloud-providers";
import { DeploymentType } from "./deployment";
import { HIRL } from "./hirl";

export enum AppServerAppVariant {
    omni = 'omni',
    smtp = 'smtp',
    themes = 'themes',
    stardate = 'stardate',
    custom = 'custom',
    meetProxy = 'meetProxy',
}

export interface AppServerCustomConfig {
    github: string;
}

export enum AppServerStatusEnum {
    ok = 'ok',
    degraded = 'degraded',
    unstable = 'unstable',
    initializing = 'initializing',
}

export interface AppServerDomainType {
    host: string;
    active: boolean;   
}

export interface AppServerCredentialsType {
    /**
     * Key this service will use to sync with Hiyllo Infrastructure Command
     */
    hicSyncKey: string;
}

export interface AppServerHostingType_DigitalOceanAppPlatform {
    location: CloudProvider.digitalOceanAppPlatform;
    geographicLocation: GeographicLocation;
    uuid: string;
}

export interface AppServerHostingType_DigitalOceanDroplet {
    location: CloudProvider.digitalOceanDroplet;
    geographicLocation: GeographicLocation;
    uuid: string;
    operatingSystem: OperatingSystem;
}

export interface AppServerHostingType_AWSEC2 {
    location: CloudProvider.awsEC2;
    geographicLocation: GeographicLocation;
    instanceId: string;
}

export type AppServerHostingType = AppServerHostingType_DigitalOceanAppPlatform | AppServerHostingType_AWSEC2 | AppServerHostingType_DigitalOceanDroplet;


export type AppServerSpecType = {
    /**
     * Number of instances (horizontal scaling)
     */
    horizontalCount: number;

    memoryGB: number;
    vCPU: number;
    dedicatedCPU: boolean;
}

export interface AppServerStaticIPType {
    ip: string;
    host: string;
}

export interface AppServerType {
    uuid: string;
    name: string;
    variant: AppServerAppVariant;
    custom?: AppServerCustomConfig | null;
    
    spec: AppServerSpecType;
    hosting: AppServerHostingType | null;
    domains: AppServerDomainType[];
    staticIP?: AppServerStaticIPType | null;
    deployment: DeploymentType;
    status: AppServerStatusEnum;
    created: Date;
    modified: Date;
    updated: Date;
    activeOperation?: string | null;
    hiylloS2SPublicKey?: string | null;
    hiylloNetworkDomain: string;
    
    /**
     * Native host which other CNAMES can point to
     */
    defaultHost: string;
    
    
    credentials: AppServerCredentialsType;
    
}

export { CloudProvider, GeographicLocation };