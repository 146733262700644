/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { AppServerAppVariant, CloudProvider } from '../types/infrastructure/app-server';

export interface InfrastructureAppVariant {
    label: string;
    requiresMongo: boolean;
    permittedHostingLocations: CloudProvider[];
    github: string | null;
    startFile: string;
}

export const InfrastructureAppVariants: Record<AppServerAppVariant, InfrastructureAppVariant> = {
    [AppServerAppVariant.custom]: {
        label: 'Custom',
        requiresMongo: false,
        permittedHostingLocations: [CloudProvider.digitalOceanAppPlatform],
        github: null,
        startFile: 'bundle/start.js'
    },
    [AppServerAppVariant.omni]: {
        label: 'Omni',
        requiresMongo: true,
        permittedHostingLocations: [CloudProvider.digitalOceanAppPlatform],
        github: null,
        startFile: 'bundle/start.js'
    },
    [AppServerAppVariant.smtp]: {
        label: 'SMTP',
        requiresMongo: false,
        permittedHostingLocations: [CloudProvider.awsEC2],
        github: null,
        startFile: 'bundle/start.js'
    },
    [AppServerAppVariant.stardate]: {
        label: 'Stardate',
        requiresMongo: true,
        permittedHostingLocations: [CloudProvider.digitalOceanAppPlatform],
        github: 'hiyllo/stardate-web',
        startFile: 'bundle/start.js'
    },
    [AppServerAppVariant.themes]: {
        label: 'Themes',
        requiresMongo: true,
        permittedHostingLocations: [CloudProvider.digitalOceanAppPlatform],
        github: 'hiyllo/hiyllo-themes',
        startFile: 'bundle/start.js'
    },
    [AppServerAppVariant.meetProxy]: {
        label: 'Meet Proxy',
        requiresMongo: false,
        permittedHostingLocations: [CloudProvider.digitalOceanDroplet],
        github: 'hiyllo/meet-proxy',
        startFile: 'index.js'
    }
}