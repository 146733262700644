/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import type { BuildRepoEntry } from '../../types/build-repo'

export type ParamsType = null;
export type ResponseType = { builds: (BuildRepoEntry & { src: string })[] };
export const Endpoint = 'build-repo/get-builds-list';
export const Method = 'GET';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
};export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}