import React from 'react';
import { seamlessAuth } from '../../seamless';

export const Home = (): JSX.Element => {
  const authState = seamlessAuth.useAuthState();

  return (
    <div>
      Hi, {authState?.userId}
    </div>
  );
};
