import React from 'react';
import { Button } from '@hiyllo/ux/button';

import * as ListAppServersBP from '../../../../blueprints/infrastructure/list-app-servers';
import { PageContainer } from '../../../ux/page-container';
import { FlexRow } from '../../../ux/flex-row';
import { useNavigate } from 'react-router-dom';
import { seamlessClient } from '../../../../seamless';
import { LoadingSpinner } from '@hiyllo/ux/loading-spinner';
import { Typography } from '@hiyllo/ux/typography';

export const AppServersList = React.memo(function AppServersList (): JSX.Element {
  const navigate = useNavigate();
  const appServersQuery = seamlessClient.useQuery<ListAppServersBP.Plug>(ListAppServersBP, null);

  return (
    <PageContainer>
      <FlexRow>
        <Typography.Header>App Servers</Typography.Header>
        <div style={{flexGrow:1}}/>
        <Button
          label="Create App Server"
          onClick={() => {
            navigate('/app-servers/create');
          }}
        />
      </FlexRow>
      {appServersQuery.isLoading ? <LoadingSpinner/> : appServersQuery.isError ? <div>{appServersQuery.error.message}</div> : (
        <div style={{display:"flex", flexDirection:"column", paddingTop:16, paddingBottom:16, gap:8}}>
          {appServersQuery.data.appServers.map((appServer) => (
            <FlexRow key={appServer.uuid} style={{cursor:"pointer"}} onClick={() => {
              navigate('/app-server/' + appServer.uuid);
            }}>
              <div>{appServer.name}</div>
            </FlexRow>
          ))}
        </div>
      )}
    </PageContainer>
  );
});
