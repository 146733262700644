import React from "react";
import { PageContainer } from "../../../ux/page-container";
import { Typography } from "@hiyllo/ux/typography";
import { Select } from "@hiyllo/ux/select";
import { useNavigate } from "react-router-dom";
import { seamlessClient } from "../../../../seamless";
import { useAlertDialog } from '@hiyllo/ux/dialogs';
import * as ProvisionAppServerBP from '../../../../blueprints/infrastructure/provision-app-server';
import { AppServerAppVariant, GeographicLocation, CloudProvider } from "../../../../types/infrastructure/app-server";
import { SeamlessError } from "@seamlessjs/core/main";
import { Input } from "@hiyllo/ux/input";
import { FlexRow } from "../../../ux/flex-row";
import { Button } from "@hiyllo/ux/button";
import { ResourcePicker } from "../components/resource-picker";
import { HIRL } from "../../../../types/infrastructure/hirl";
import { InfrastructureAppVariants } from "../../../../consts/app-variants";

const ProvisionAppServerForm = React.memo(function ProvisionAppServerForm (): JSX.Element {
  const navigate = useNavigate();
  const provisionAppServerMutation = seamlessClient.useMutation<ProvisionAppServerBP.Plug>(ProvisionAppServerBP);

  const [name, setName] = React.useState<string>('');
  const [variant, setVariant] = React.useState<AppServerAppVariant>(AppServerAppVariant.omni);
  const [cloudProvider, setCloudProvider] = React.useState<CloudProvider>(CloudProvider.digitalOceanAppPlatform);
  const [databaseHIRL, setDatabaseHIRL] = React.useState<HIRL | null>(null);
  const [geo, setGeo] = React.useState<GeographicLocation>(GeographicLocation.usnortheast);
  const showAlert = useAlertDialog();

  const onSubmit = React.useCallback(() => {
    if(databaseHIRL == null && InfrastructureAppVariants[variant]?.requiresMongo) {
      void showAlert({
        title: 'Mongo Required',
        message: 'The app variant you have selected requires the selection of a Mongo database.'
      });
      return;
    }

    provisionAppServerMutation.call({
      name,
      hostLocation: cloudProvider,
      variant,
      location: geo,
      mongo: databaseHIRL,
    }).then((res) => {
      navigate('/operation/' + res.operationUUID);
    }).catch(err => {
      alert((err as SeamlessError).message);
    });
  }, [cloudProvider, databaseHIRL, geo, name, navigate, provisionAppServerMutation, showAlert, variant]);

  const selectedVariant = InfrastructureAppVariants[variant] ?? null;

  return (
    <div style={{display:"flex", flexDirection:"column", gap:16}}>
      <div>
        <Typography.Label>Variant</Typography.Label>
        <Select
          value={variant}
          onChangeValue={(value) => setVariant(value as AppServerAppVariant)}
          options={Object.entries(InfrastructureAppVariants).map(([value, data]) => ({
            value,
            label: data.label
          }))}
        />
      </div>
      <div>
        <Typography.Label>Name</Typography.Label>
        <Input
          value={name}
          onChangeValue={(value) => setName(value)}
        />
      </div>
      {selectedVariant.requiresMongo ?
        <div>
          <Typography.Label>Mongo</Typography.Label>
          <ResourcePicker
            filter='app/database'
            value={databaseHIRL}
            onChange={setDatabaseHIRL}
          />
        </div>
      : null}
      <div>
        <Typography.Label>Cloud Provider</Typography.Label>
        <Select
          value={cloudProvider}
          onChangeValue={(value) => setCloudProvider(value as CloudProvider)}
          options={[
            {
              label: "Digital Ocean App Platform",
              value: CloudProvider.digitalOceanAppPlatform
            },
            {
              label: "AWS EC2",
              value: CloudProvider.awsEC2
            },
            {
              label: "DigitalOcean Droplet",
              value: CloudProvider.digitalOceanDroplet
            }
          ]}
        />
      </div>      
      <div>
        <Typography.Label>Geographic Location</Typography.Label>
        <Select
          value={geo}
          onChangeValue={(value) => setGeo(value as GeographicLocation)}
          options={[
            {
              label: "US Northeast",
              value: GeographicLocation.usnortheast
            },
            {
              label: "Southeast Asia",
              value: GeographicLocation.southeastasia
            },
          ]}
        />
      </div>      
      <div>
        <FlexRow>
          <Button
            label="Submit"
            onClick={onSubmit}
            isLoading={provisionAppServerMutation.isLoading}
          />
        </FlexRow>
      </div>
    </div>
  );
});

export const CreateAppServer = React.memo(function CreateAppServer (): JSX.Element {
  return (
    <PageContainer>
      <Typography.Header>Create App Server</Typography.Header>
      <div style={{ height: 16 }}/>
      <ProvisionAppServerForm/>
    </PageContainer>
  );
});
