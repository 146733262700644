import { Button } from '@hiyllo/ux/button';
import React from 'react';

export const DangerButton = React.memo(function DangerButton ({label, onClick}:{label:string, onClick:() => void}): JSX.Element {
    const [isConfirming, setIsConfirming] = React.useState(false);

    const onClickInner = React.useCallback(() => {
        if(isConfirming) {
            onClick();
            
        }
        else {
            setIsConfirming(true);
            setTimeout(() => {
                setIsConfirming(false);
            }, 5000);
        }
    }, [isConfirming, onClick]);

    return (
        <div style={{display:"flex"}}>
            <Button
                isSecondary
                onClick={onClickInner}
                label={isConfirming ? 'Click again to confirm: ' + label : label}
                style={isConfirming ? {background:'red'} : {}}
            />
        </div>
    );
  });