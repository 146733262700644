/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { HIRL } from '../../types/infrastructure/hirl';
import { LinkageType } from '../../types/infrastructure/linkage';

export type ParamsType = {
  resource: HIRL;
};
export type ResponseType = {
  linkages: LinkageType[];
};
export const Endpoint = 'infrastructure/get-linkages';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"resource":{"anyOf":[{"type":"object","properties":{"kind":{"type":"string","enum":["app/server"]},"uuid":{"type":"string"}},"required":["kind","uuid"]},{"type":"object","properties":{"kind":{"type":"string","enum":["app/database"]},"uuid":{"type":"string"}},"required":["kind","uuid"]},{"type":"object","properties":{"kind":{"type":"string","enum":["app/postgres"]},"uuid":{"type":"string"}},"required":["kind","uuid"]},{"type":"object","properties":{"kind":{"type":"string","enum":["app/redis"]},"uuid":{"type":"string"}},"required":["kind","uuid"]},{"type":"object","properties":{"kind":{"type":"string","enum":["aws/s3-bucket"]},"uuid":{"type":"string"}},"required":["kind","uuid"]}]}},"required":["resource"],"$schema":"http://json-schema.org/draft-07/schema#"}