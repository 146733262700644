import React from 'react';
import { Button } from '@hiyllo/ux/button';

import * as ListDatabasesBP from '../../../../blueprints/infrastructure/list-databases';
import { PageContainer } from '../../../ux/page-container';
import { FlexRow } from '../../../ux/flex-row';
import { useNavigate } from 'react-router-dom';
import { seamlessClient } from '../../../../seamless';
import { LoadingSpinner } from '@hiyllo/ux/loading-spinner';
import { DatabaseStatusEnum } from '../../../../types/infrastructure/database';
import { Typography } from '@hiyllo/ux/typography';

const StatusIndicator = React.memo(function StatusIndicator ({status}:{status:DatabaseStatusEnum}): JSX.Element {
  return (
    <div
    style={{
      height: 16,
      width: 16,
      borderRadius: '50%',
      background: status === DatabaseStatusEnum.online ? 'green' : status === DatabaseStatusEnum.initializing ? 'blue' : 'yellow'
    }}
    />
  );
});

export const DatabasesList = React.memo(function DatabasesList (): JSX.Element {
  const navigate = useNavigate();
  const databasesQuery = seamlessClient.useQuery<ListDatabasesBP.Plug>(ListDatabasesBP, null);

  return (
    <PageContainer>
      <FlexRow>
        <Typography.Header>Databases</Typography.Header>
        <div style={{flexGrow:1}}/>
        <Button
          label="Create Database"
          onClick={() => {
            navigate('/databases/create');
          }}
        />
      </FlexRow>
      {databasesQuery.isLoading ? <LoadingSpinner/> : databasesQuery.isError ? <div>{databasesQuery.error.message}</div> : (
        <div style={{display:"flex", flexDirection:"column", paddingTop:16, paddingBottom:16, gap:8}}>
          {databasesQuery.data.databases.map((database) => (
            <FlexRow key={database.uuid} style={{cursor:"pointer"}} onClick={() => {
              navigate('/database/' + database.uuid);
            }}>
              <StatusIndicator status={database.status}/>
              <div>{database.name}</div>
            </FlexRow>
          ))}
        </div>
      )}
    </PageContainer>
  );
});
