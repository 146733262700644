/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export enum CloudProvider {
    digitalOceanAppPlatform = 'digitalOceanAppPlatform',
    digitalOceanDroplet = 'digitalOceanDroplet',
    awsEC2 = 'awsEC2',
}

export enum GeographicLocation {
    usnortheast = "usnortheast",
    southeastasia = "southeastasia"
}
