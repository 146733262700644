import * as GetLinkagesBP from '../../../../../blueprints/infrastructure/get-linkages';
import { seamlessClient } from '../../../../../seamless';
import { HIRL } from '../../../../../types/infrastructure/hirl';

export function useGetLinkages(resource: HIRL): HIRL[] {
    const linkages = seamlessClient.useQuery<GetLinkagesBP.Plug>(GetLinkagesBP, { resource }).data?.linkages ?? [];

    const linkedResources: HIRL[] = linkages.map((linkage): HIRL | null => linkage.resources.find(r => r.uuid !== resource.uuid) ?? null).filter((v): v is HIRL => v != null);

    return linkedResources;
}