import React from 'react';
import { Button } from '@hiyllo/ux/button';

import * as GetAppServerBP from '../../../../blueprints/infrastructure/get-app-server';
import * as UpdateAppServerBP from '../../../../blueprints/infrastructure/update-app-server';
import * as LinkDatabaseToAppServerBP from '../../../../blueprints/infrastructure/link-database-to-app-server';
import * as PatchAppServerCredentialsBP from '../../../../blueprints/infrastructure/patch-app-server-credentials';
import * as GetAppRawBP from '../../../../blueprints/infrastructure/get-app-raw';
import * as DumpLogsBP from '../../../../blueprints/infrastructure/dump-logs';
import * as TerminateAppServerBP from '../../../../blueprints/infrastructure/terminate-app-server';
import * as AssignIPToAppServerBP from '../../../../blueprints/infrastructure/assign-ip-to-app-server';
import { PageContainer } from '../../../ux/page-container';
import { FlexRow } from '../../../ux/flex-row';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { seamlessClient } from '../../../../seamless';
import {LoadingSpinner } from '@hiyllo/ux/loading-spinner';
import { Typography } from '@hiyllo/ux/typography';
import { Card } from '@hiyllo/ux/surface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faDatabase, faGlobe, faSearch, faServer } from '@fortawesome/pro-light-svg-icons';
import { AppServerType } from '../../../../types/infrastructure/app-server';
import { UseMoopsyQueryRetValAny } from '@moopsyjs/react';
import { ResourcePicker } from '../components/resource-picker';
import { HIRL } from '../../../../types/infrastructure/hirl';
import { Input } from '@hiyllo/ux/input';
import { CircleButton } from '@hiyllo/ux/circle-button';
import { useGetLinkages } from '../linkages/hooks/get-linkages';
import { DangerButton } from '../../../ux/danger-button';

const AppServerQueryCtx = React.createContext<UseMoopsyQueryRetValAny | null>(null);
const AppServerCtx = React.createContext<AppServerType | null>(null);

export function useAppServer (): AppServerType {
  const ctx = React.useContext(AppServerCtx);

  if(ctx == null) {
    throw new Error('AppServerCtx is required');
  }

  return ctx;
}

const LinkDatabaseSubCard = React.memo(function LinkDatabaseSubCard (params: {
  kind: HIRL['kind']
}): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [databaseHIRL, setDatabaseHIRL] = React.useState<HIRL | null>(null);
  const appServer = useAppServer();
  const linkDatabaseToAppServerMutation = seamlessClient.useMutation<LinkDatabaseToAppServerBP.Plug>(LinkDatabaseToAppServerBP);

  const submit = React.useCallback(() => {
    if(databaseHIRL == null) {
      throw new Error('database is required');
    }

    void linkDatabaseToAppServerMutation.call({
      database: databaseHIRL,
      appServer: {
        kind: 'app/server',
        uuid: appServer.uuid
      }
    }).then(res => {
      navigate('/operation/' + res.operationUUID + "?returnTo=" + encodeURIComponent(location.pathname));
    });
  }, [appServer.uuid, databaseHIRL, linkDatabaseToAppServerMutation, location.pathname, navigate]);

  return (
    <FlexRow>
      <ResourcePicker
        filter={params.kind}
        value={databaseHIRL}
        onChange={setDatabaseHIRL}
      />
      {databaseHIRL == null ? null :
        <Button
          onClick={submit}
          label="Link Database"
        />
      }
    </FlexRow>
  );
});

const DatabaseCard = React.memo(function DatabaseCard ({linkages}:{linkages:HIRL[]}): JSX.Element {
  const linkedDatabase = linkages.find(resource => resource.kind === 'app/database');

  return (
    <Card color="background3">
      <FlexRow>
        <FontAwesomeIcon icon={faDatabase}/>
        <Typography.SubHeader>Database</Typography.SubHeader>
      </FlexRow>
      {linkedDatabase == null || linkedDatabase.kind !== 'app/database' ? 
        <div>
          <div>
            <b>No Database Linked</b>
            <LinkDatabaseSubCard kind="app/database" />
          </div>
        </div>
      :
        <div>
          {linkedDatabase.kind + '.' + linkedDatabase.uuid}
        </div>
      }
    </Card>
  );
});

const PostgresCard = React.memo(function PostgresCard ({linkages}:{linkages:HIRL[]}): JSX.Element {
  const linkedDatabase = linkages.find(resource => resource.kind === 'app/postgres');

  return (
    <Card color="background3">
      <FlexRow>
        <FontAwesomeIcon icon={faSearch}/>
        <Typography.SubHeader>PostgresDB</Typography.SubHeader>
      </FlexRow>
      {linkedDatabase == null ? 
        <div>
          <div>
            <b>No Postgres Linked</b>
            <LinkDatabaseSubCard kind="app/postgres" />
          </div>
        </div>
      :
        <div>
          {linkedDatabase.kind + '.' + linkedDatabase.uuid}
        </div>
      }
    </Card>
  );
});


const NetworkingCard = React.memo(function NetworkingCard (props: {
  appServer: AppServerType
}): JSX.Element {
  const navigate = useNavigate();
  const [domain, setDomain] = React.useState<string>('');
  const assignIPToAppServerMutation = seamlessClient.useMutation<AssignIPToAppServerBP.Plug>(AssignIPToAppServerBP);

  const submit = React.useCallback(() => {
    void assignIPToAppServerMutation.call({
      appServer: {
        kind: 'app/server',
        uuid: props.appServer.uuid
      },
      host: domain
    }).then(res => {
      navigate('/operation/' + res.operationUUID + "?returnTo=" + encodeURIComponent(location.pathname));
    });
  }, [assignIPToAppServerMutation, domain, navigate, props.appServer.uuid]);

  return (
    <Card color="background3">
      <FlexRow>
        <FontAwesomeIcon icon={faGlobe}/>
        <Typography.SubHeader>Networking</Typography.SubHeader>
      </FlexRow>
      {props.appServer.staticIP != null ?
        <div>
          Currently pointing to {props.appServer.staticIP.ip} ({props.appServer.staticIP.host})
        </div>
      : null}
      <FlexRow>
        <Input
          placeholder='Domain'
          value={domain}
          onChangeValue={setDomain}
        />
        <CircleButton
          icon={faArrowRight}
          onClick={submit}
        />
      </FlexRow>
      <div>
        Default Host: {props.appServer.defaultHost}
      </div>
      <div>
        Hiyllo Network Host: {props.appServer.hiylloNetworkDomain}
      </div>
      <div>
        Metal Location: {props.appServer.hosting?.geographicLocation}
      </div>
    </Card>    
  );
});

export const AppServerView = React.memo(function AppServerView (): JSX.Element {
  const { uuid } = useParams<{uuid:string}>();

  if(uuid == null) {
    throw new Error('uuid is required');
  }

  const appServerQuery = seamlessClient.useQuery<GetAppServerBP.Plug>(GetAppServerBP, {uuid});
  const updateAppServerMutation = seamlessClient.useMutation<UpdateAppServerBP.Plug>(UpdateAppServerBP);
  const patchAppServerCredentialsMutation = seamlessClient.useMutation<PatchAppServerCredentialsBP.Plug>(PatchAppServerCredentialsBP);
  const getAppRawMutation = seamlessClient.useMutation<GetAppRawBP.Plug>(GetAppRawBP);
  const dumpLogsMutation = seamlessClient.useMutation<DumpLogsBP.Plug>(DumpLogsBP);
  const terminateAppServerMutation = seamlessClient.useMutation<TerminateAppServerBP.Plug>(TerminateAppServerBP);
  const linkages = useGetLinkages({
    kind: 'app/server',
    uuid
  });
  const navigate = useNavigate();

  const update = React.useCallback(() => {
    void updateAppServerMutation.call({
      appServer: {
        kind: 'app/server',
        uuid
      }
    }).then(res => {
      navigate('/operation/' + res.operationUUID + "?returnTo=" + encodeURIComponent(location.pathname));
    });
  }, [navigate, updateAppServerMutation, uuid]);

  const terminate = React.useCallback(() => {
    void terminateAppServerMutation.call({
      appServer: {
        kind: 'app/server',
        uuid
      }
    }).then(() => {
      navigate('/app-servers');
    });
  }, [navigate, terminateAppServerMutation, uuid]);

  const patchCredentials = React.useCallback(() => {
    void patchAppServerCredentialsMutation.call({
      appServer: {
        kind: 'app/server',
        uuid
      }
    }).then(res => {
      navigate('/operation/' + res.operationUUID + "?returnTo=" + encodeURIComponent(location.pathname));
    });
  }, [navigate, patchAppServerCredentialsMutation, uuid]);

  const getRaw = React.useCallback(() => {
    void getAppRawMutation.call({
      appServer: {
        kind: 'app/server',
        uuid
      }
    }).then(res => {
      console.log(res);
    });
  }, [getAppRawMutation, uuid]);

  const [logs, setLogs] = React.useState<string | null>(null);

  const dumpLogs = React.useCallback(() => {
    void dumpLogsMutation.call({
      appServer: {
        kind: 'app/server',
        uuid
      }
    }).then(res => {
      setLogs(res.result);
    });
  }, [dumpLogsMutation, uuid]);

  if(appServerQuery.isLoading) {
    return (
      <PageContainer>
        <LoadingSpinner/>
      </PageContainer>
    );
  }

  if(appServerQuery.isError) {
    return (
      <PageContainer>
        {appServerQuery.error.message}
      </PageContainer>
    );
  }  

  const {appServer, activeOperation} = appServerQuery.data;

  return (
    <AppServerCtx.Provider value={appServer}>
      <AppServerQueryCtx.Provider value={appServerQuery}>
        <PageContainer>
          {activeOperation != null ?
            <Link to={'/operation/' + activeOperation.uuid + "?returnTo=" + window.location.pathname}>
              <div style={{backgroundColor:"#2196f3", padding:16, borderRadius:8, display:"flex", flexDirection:"row", alignItems:"center", gap:8, cursor:"pointer", marginBottom:16, color:"white", textDecoration:"underline"}}>
                <LoadingSpinner/>
                <b>Active Operation: </b>{activeOperation.label}
              </div>
            </Link>
          : null}
          <Typography.Header>
            {appServer.name}
          </Typography.Header>
          <div style={{height:16}}/>
          <Card color="background3">
            <FlexRow>
              <FontAwesomeIcon icon={faServer}/>
              <Typography.SubHeader>Infrastructure</Typography.SubHeader>
            </FlexRow>
            <FlexRow>
              <div>
                <b>Server Count: </b> {appServer.spec.horizontalCount}
              </div>
              <div>
                <b>Memory: </b> {appServer.spec.memoryGB}GB
              </div>
              <div>
                <b>vCPU: </b> {appServer.spec.vCPU}
              </div>
            </FlexRow>
          </Card>
          <div style={{height:16}}/>
          <DatabaseCard linkages={linkages} />
          <div style={{height:16}}/>
          <PostgresCard linkages={linkages} />
          <div style={{height:16}}/>
          <NetworkingCard appServer={appServer}/>
          <div style={{height:16}}/>          
          <Card color="background3">
            <FlexRow>
              <Button
                label="Update"
                onClick={update}
              />
              <Button
                label="Patch Credentials"
                onClick={patchCredentials}
              />
              <Button
                label="Get Raw"
                onClick={getRaw}
              />
              <Button
                label="Dump Log"
                onClick={dumpLogs}
              />              
              <div style={{flexGrow:1}}/>
              <DangerButton
                label="Terminate"
                onClick={terminate}
              />              
            </FlexRow>
          </Card>
          {logs != null ?
                                  <Card color="background3">
                                  <div style={{whiteSpace:"pre-wrap"}}>
                                      {logs}
                                  </div>
                              </Card>
        : null}
        </PageContainer>
      </AppServerQueryCtx.Provider>
    </AppServerCtx.Provider>
  );
});
