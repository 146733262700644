import React from 'react';
import {
  BrowserRouter, Link, Route, Routes
} from 'react-router-dom';
import { Home } from './view/home';
import { BuildsList } from './view/build-repo/builds-list';
import { styled } from '@hiyllo/ux/styled';
import { DatabasesList } from './features/infrastructure/databases/databases-list';
import { CreateDatabase } from './features/infrastructure/databases/create-database';
import { DatabaseView } from './features/infrastructure/databases/database-view';
import { AppServersList } from './features/infrastructure/app-servers/app-servers-list';
import { CreateAppServer } from './features/infrastructure/app-servers/create-app-server';
import { AppServerView } from './features/infrastructure/app-servers/app-server-view';
import { OperationView } from './features/infrastructure/operations/operation-view';

const Outer = styled('div', ({ $theme }) => ({
  background: $theme.background1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  color: $theme.foreground,
  fontFamily: 'hiyllo'
}));

const PageContainer = styled('div', ({ $theme }) => ({
  background: $theme.background1,
  height: 0,
  flexGrow: 1,
  overflowY: 'auto'
}));

const Header = styled('div', ({ $theme }) => ({
  background: $theme.background2,
  height: 64,
  display: 'flex',
  alignItems: 'center',
  padding: '0 32px',
  gap: 16
}));

const SubtleButton = styled('button', ({ $theme }) => ({
  background: $theme.background3,
  cursor: 'pointer',
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 16,
  paddingRight: 16,
  borderRadius: 8,
  color: $theme.foreground,
  fontFamily: 'hiyllo',
  border: 'none'
}));

export const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Outer>
        <Header>
          <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png" alt="Hiyllo Logo" style={{ height: 32 }} />
          <span style={{
            color: 'white',
            fontFamily: 'hiyllo',
            fontSize: 28,
            fontWeight: 'bold',
            paddingBottom: 0
          }}>Hiyllo Infrastructure Command</span>
          <div style={{ flexGrow: 1 }}/>
          <Link to="/app-servers">
            <SubtleButton>
              App Servers
            </SubtleButton>
          </Link>        
          <Link to="/databases">
            <SubtleButton>
              Databases
            </SubtleButton>
          </Link>
          <Link to="/builds">
            <SubtleButton>
              Builds
            </SubtleButton>
          </Link>
        </Header>
        <PageContainer>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/databases" element={<DatabasesList/>} />
            <Route path="/databases/create" element={<CreateDatabase/>} />
            <Route path="/database/:uuid" element={<DatabaseView/>} />
            
            <Route path="/app-servers" element={<AppServersList/>} />
            <Route path="/app-servers/create" element={<CreateAppServer/>} />
            <Route path="/app-server/:uuid" element={<AppServerView/>} />

            <Route path="/operation/:uuid" element={<OperationView/>} />

            <Route path="/builds" element={<BuildsList/>} />
          </Routes>
        </PageContainer>
      </Outer>
    </BrowserRouter>
  );
};
