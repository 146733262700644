/* eslint-disable no-useless-call */
import React from 'react';
import * as RequestLoginBP from './blueprints/auth/request-login';
import * as ProcessLoginBP from './blueprints/auth/process-login';
import { seamlessAuth, seamlessClient } from './seamless';
import { MoopsyAuthState } from '@moopsyjs/react';
import { Router } from './src/router';

export const App = (): React.ReactElement => {
  const authStatus = seamlessAuth.useAuthStatus();
  const requestLoginMutation = seamlessClient.useMutation<RequestLoginBP.Plug>(RequestLoginBP);
  const processLoginMutation = seamlessClient.useMutation<ProcessLoginBP.Plug>(ProcessLoginBP);

  React.useEffect(() => {
    const usp = new window.URLSearchParams(window.location.search);
    const params = {
      SAMLResponse: usp.get('SAMLResponse') as string,
      RelayState: usp.get('RelayState') as string
    };

    if (window.location.pathname === '/sso' && params.SAMLResponse != null) {
      void processLoginMutation.call({
        SAMLResponse: params.SAMLResponse,
        RelayState: params.RelayState
      }).then(({ token }) => {
        window.sessionStorage.setItem('token', token);
        const path = decodeURIComponent(params.RelayState);
        window.history.pushState({}, '', path === '/sso' ? '/' : path);
        window.location.reload();
      });
    } else {
      if (window.sessionStorage.token == null) {
        void requestLoginMutation.call(null).then(({ href }) => {
          const url = new URL(href);
          url.searchParams.set('RelayState', encodeURIComponent(window.location.pathname));
          window.location.href = url.toString();
        });
      }
    }
  }, []);

  if (authStatus === MoopsyAuthState.loggedIn) {
    return <Router/>;
  }

  return (
    <div>
      Logging in...
    </div>
  );
};
